import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  ios: boolean;
  android: boolean;
  env: 'dev' | 'prod' = 'prod';
  testBackend = false;
  public url: string;
  private _isOpen: BehaviorSubject<{
    keyboardVisible: boolean;
    keyboardHeight: number;
    noKeyboardSpace: number;
  }> = new BehaviorSubject({
    keyboardVisible: false,
    keyboardHeight: 0,
    noKeyboardSpace: 0,
  });

  public isOpen$ = this._isOpen.asObservable();
  initialWindowHeight: number = 0;

  constructor(public platform: Platform) {
    window.addEventListener('resize', this.detectKeyboard.bind(this));
    this.initialWindowHeight = window.innerHeight;

    this.ios = platform.is('ios');
    this.android = platform.is('android');

    const loc = window.location.hostname;
    if (loc === 'localhost') {
      this.env = 'dev';
    }
    console.log('-> loc', loc);
    if (loc == 'localhost' && this.testBackend) {
      this.url = 'https://backend-dev.linget.it';

      // this.url = 'https://test.lingking.com.pl:3001';
      console.log('-> url', this.url);
    } else {
      // this.url = 'http://' + window.location.hostname git add . && git commit -m "Deployment" && git push origin stable2 && rsync -avz  ~/dev/backend/ krzjac@backend-utils.linget.it:/home/krzjac/backend/ && ssh krzjac@backend-utils.linget.it 'cd backend; pm2 stop serverSSL.js; pm2 start serverSSL.js; exit' && rsync -avz  ~/dev/backend/ krzjac@backend-int.linget.it:/home/krzjac/backend/ && ssh krzjac@backend-int.linget.it 'cd backend; pm2 stop serverSSL.js; pm2 start serverSSL.js; exit' && echo 'Backend deployment finished successfully' + ':3000';
      // this.url = 'http://' + 'krzjac2.ddns.net' + ':3000';
      // this.url = 'https://lingking.com.pl:3001';
      if (loc.includes('alt')) {
        this.url = 'https://backend-backup.linget.it';
      } else {
        this.url = 'https://backend-dev.linget.it';
      }
      console.log('-> url', this.url);
    }
  }
  private detectKeyboard() {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const keyboardHeightThreshold = 150;

    const isLandscape = windowWidth > windowHeight;
    const screenSize = isLandscape ? windowWidth : windowHeight;

    if (screenSize - windowHeight > keyboardHeightThreshold) {
      this._isOpen.next({
        keyboardVisible: true,
        keyboardHeight: this.initialWindowHeight - windowHeight,
        noKeyboardSpace:
          this.initialWindowHeight - (this.initialWindowHeight - windowHeight),
      });
    } else {
      this._isOpen.next({
        keyboardVisible: false,
        keyboardHeight: 0,
        noKeyboardSpace: this.initialWindowHeight,
      });
    }
  }
}
